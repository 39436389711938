import * as React from "react"
import { withPrefix } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"

const DatenschutzPage = ({ location }) => (
  
  <>
  {/* Hero section  */}
  <Layout
    marginTop={50}
    noContact
    breadcrumb={
      {"Datenschutz": "/datenschutz"}
    }
  >
    <Seo 
      title="Datenschutz | showcase21"
      description="Hier findest du alle wichtigen Infos zur Nutzung deiner Daten beim Besuch von showcase21.de"
      lang="de"
      pathname={location.pathname}
      robots="noindex,follow"
    />
    <Section>
      <div className="row">
        <div className="col-md-10">
          <h1>Datenschutz</h1>
          <p className="subheading align-left">Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist: <br />Tobias Dölker</p>
        </div>
      </div>
    </Section>
    
    {/* Betroffenenrechte */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <h2 className="left medium">Ihre Betroffenenrechte</h2>
          <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
          <ul>
            <li className="styled">Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
            <li className="styled">Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
            <li className="styled">Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
            <li className="styled">Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
            <li className="styled">Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
            <li className="styled">Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
          </ul>
          <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen. <br/>
              Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.<br/>
              Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.</p>
        </div>
      </div>
    </Section>

  {/* Erfassung allgemeiner Informationen beim Besuch der Website */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Erfassung allgemeiner Informationen beim Besuch der Website</h2>
            <h3 className="small">Art und Zweck der Verarbeitung:</h3>
            <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, 
              werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, 
              das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. <br/>
              Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
            <ul>
              <li className="styled">Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
              <li className="styled">Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
              <li className="styled">Auswertung der Systemsicherheit und -stabilität sowie</li>
              <li className="styled">zur Optimierung unserer Website.</li>              
            </ul>
            <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Rechtsgrundlage und berechtigtes Interesse:</h3>
            <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Empfänger:</h3>
            <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Drittlandtransfer:</h3>
            <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
            <p>USA</p>
            <p>Folgende Datenschutzgarantien liegen vor:</p>
            <p>Standard-Vertragsklauseln</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Speicherdauer:</h3>
            <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.<br />
              Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.</p>
          </div>
        </div>
      </div>
    </Section>

    {/* Cookies */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Cookies</h2>
            <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Webseite besuchen.<br />
              Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links:</p>
            <ul>
              <li className="styled">Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a></li>
              <li className="styled">Internet Explorer: <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
              <li className="styled">Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de">https://support.google.com/accounts/answer/61416?hl=de</a></li>
              <li className="styled">Opera: <a href="http://www.opera.com/de/help">http://www.opera.com/de/help</a></li>
              <li className="styled">Safari: <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a></li>
            </ul>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Speicherdauer und eingesetzte Cookies:</h3>
            <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz kommen:</p>
          </div>
          
        </div>
      </div>

      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Technisch notwendige Cookies</h2>
            <h3 className="small">Art und Zweck der Verarbeitung:</h3>
            <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.<br />
              Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.<br/>
              Für folgende Anwendungen benötigen wir Cookies:</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Rechtsgrundlage und berechtigtes Interesse:</h3>
            <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung unserer Website.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Empfänger:</h3>
            <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Widerspruch:</h3>
            <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Technisch nicht notwendige Cookies</h2>
            <p>Des Weiteren setzen wir Cookies ein, um das Angebot auf unserer Website besser auf die Interessen unserer Besucher abzustimmen oder auf Basis statistischer Auswertungen allgemein zu verbessern.<br/>
              Welche Anbieter Cookies setzen, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Rechtsgrundlage:</h3>
            <p>Rechtsgrundlage für diese Verarbeitungen ist jeweils Ihre Einwilligung, Art. 6 Abs. 1 lit. a DSGVO.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Empfänger:</h3>
            <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.<br />
              Weitere Empfänger entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Drittlandtransfer:</h3>
            <p>Informationen hierzu entnehmen Sie bitte aus den Auflistungen der einzelnen Darstellungs-, Tracking-, Remarketing- und Webanalyse-Anbietern.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Webbrowser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren (siehe Widerruf der Einwilligung).<br />
              Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Widerruf der Einwilligung:</h3>
            <p>Sie können Ihre Einwilligung jederzeit über unser Cookie-Consent-Tool widerrufen.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Profiling:</h3>
            <p>Inwiefern wir das Verhalten von Websitebesuchern mit pseudonymisierten Nutzerprofilen analysieren, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.</p>
          </div>
        </div>
      </div>
    </Section>

    {/* Kontaktformular */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Kontaktformular</h2>
            <h3 className="small">Art und Zweck der Verarbeitung:</h3>
            <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Rechtsgrundlage:</h3>
            <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).<br />
              Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.<br />
              Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Empfänger:</h3>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Drittlandtransfer:</h3>
            <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
            <p>USA</p>
            <p>Folgende Datenschutzgarantien liegen vor:</p>
            <p>Standard-Vertragsklauseln</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Speicherdauer:</h3>
            <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.<br/>
              Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
          </div>
        </div>
      </div>
    </Section>

    {/* Hosting */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Hosting</h2>
            <p>Die Webseite „showcase21.de“ wird auf Servern von einem durch uns beauftragten Dienstleister gehostet.<br/>
              Unser Dienstleister ist: <strong>Netlify Inc., 2325 3rd St #215, San Francisco, CA, USA</strong><br/><br/>
              Die Server erheben und speichern automatisch Informationen in sogenannten Server-LogDateien, die Ihr Browser beim Besuch der Webseite automatisch übermittelt. Die
              gespeicherten Informationen sind:</p>
            <ul>
              <li className="styled">Browsertyp und Browserversion</li>
              <li className="styled">Verwendetes Betriebssystem</li>
              <li className="styled">Referrer URL</li>
              <li className="styled">Hostname des zugreifenden Rechners</li>
              <li className="styled">Datum und Uhrzeit der Serveranfrage</li>
              <li className="styled">IP-Adresse</li>
            </ul>
            <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Zweck der Datenverarbeitung</h3>
            <p>Wir nutzen die Dienste von Netlify, um eine technisch fehlerfreie Website gewährleisten zu können.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
            <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
              Webseitenbetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung
              und der Optimierung seiner Webseite – hierzu müssen die Server-Log-Files erfasst werden.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Dauer der Speicherung </h3>
            <p>Ihre persönlichen Informationen werden so lange gespeichert, wie dies erforderlich ist, um
              die in dieser Datenschutzerklärung beschriebenen Zwecke zu erfüllen oder wie dies
              gesetzlich vorgeschrieben ist, z.B. für Steuer- und Buchhaltungszwecke.</p>
          </div>

          <div className="mb-5">
            <h3 className="small">Widerruf </h3>
            <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu
              widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der
              Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.<br />
              Der Standort des Servers der Webseite liegt geografisch in den USA. Zum
              datenschutzkonformen Einsatz und rechtskonformen Datentransfer in Drittländer wurden
              entsprechende Auftragsverarbeitungsverträge sowie geeignete Garantien, z. B. in Form der
              Standarddatenschutzklauseln nach Art. 46 Abs. 2 lit. c DSGVO abgeschlossen</p>
          </div>
        </div>
      </div>
    </Section>

    {/* Google Tag Manager */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Verwendung von Google Tag Manager</h2>
            <p>Google Tag Manager ist ein Dienst von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland mit dem Zweck, 
              verschiedener Skripte zur Webanalyse und zum Remarketing in die eigene Webseite vereinfacht einzubinden.<br/><br/>
              Es handelt sich nicht um einen Dienst im eigentlichen Sinne. Der Google Tag Manager reicht Daten lediglich weiter 
              und verarbeitet diese nicht selbst. Es werden keine personenbezogenen Daten gespeichert
            </p>
          </div>

        </div>
      </div>
    </Section>
    
    {/* Google Analytics */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Verwendung von Google Analytics</h2>
            <p>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. <br/><br/>
              Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.google.com/analytics/terms/de.html">https://www.google.com/analytics/terms/de.html</a> und unter <a href="https://policies.google.com/?hl=de">https://policies.google.com/?hl=de</a>.<br/><br/>
              Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.<br/><br/>
              Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Widerruf der Einwilligung:</h3>
            <p>Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie <span className="link" id="google-analytics-opt-out">diesen Link anklicken</span>. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, solange das Cookie in Ihrem Browser installiert bleibt.<br/><br/>
              Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.<br/><br/>
              Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>
          </div>

        </div>
      </div>
    </Section>

    {/* Google Webfonts 
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
            <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.<br/><br/>
              Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
          </div>
        </div>
      </div>
    </Section>
    */}

    {/* SSL */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">SSL-Verschlüsselung</h2>
            <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
          </div>
        </div>
      </div>
    </Section>

    {/* Widerspruchsrecht */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
            <h3 className="small">Einzelfallbezogenes Widerspruchsrecht</h3>
            <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.<br/><br/>
              Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Empfänger eines Widerspruchs</h3>
            <p>Tobias Dölker<br/>
              Am Marktplatz 13a,<br/>
              77652 Offenburg</p>
          </div>

        </div>
      </div>
    </Section>

    {/* Änderungen der Datenschutzbestimmungen */}
    <Section>
      <div className="row">
        <div className="col-md-10">
          <div className="mb-5">
            <h2 className="left medium">Änderung unserer Datenschutzbestimmungen</h2>
            <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
          </div>          
        
          <div className="mb-5">
            <h3 className="small">Fragen an den Datenschutzbeauftragten</h3>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:<br/><br/>
              <a href="mailto:tobias.doelker@showcase21.de">tobias.doelker@showcase21.de</a><br/><br/>
              Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</p>
          </div>

        </div>
      </div>
    </Section>

    <Helmet>
        <script src={withPrefix('script.js')} type="text/javascript" />
    </Helmet>
    
  </Layout>
  </>
)
export default DatenschutzPage
